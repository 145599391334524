@tailwind base;
@tailwind components;
@tailwind utilities;

.faq-section .bg-gray-100 {
  background-color: #facc15;
  color: #000;
}
.faq-section .accordion-content {
  border-bottom: 1px solid rgb(227, 227, 227);
}
@media only screen and (min-width: 1200px) {
  .hero-content h1 {
    line-height: 5rem !important;
  }
}
@media only screen and (max-width: 768px) {
  .hero-img {
    width: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .success-content {
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .hero-img {
    height: 500px;
    width: 400px;
  }
}

@media only screen and (min-width: 1400px) {
  .hero-img {
    height: 600px;
    width: 500px;
  }
}
