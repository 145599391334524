.plan {
  border-radius: 16px;
  padding: 10px;
  background-color: #fff;
  color: #697e91;
  max-width: 300px;
  margin: auto;
  position: relative;
  transition: all 0.2s ease-in;
}
.plan:hover {
  transform: scale(1.02);
}

.highlight {
  border: 2px solid #4caf50; /* Change border color for highlight */
  background-color: #f0fff4; /* Light background for highlight */
}

.plan strong {
  font-weight: 600;
  color: #fff;
}

.plan .inner {
  align-items: center;
  padding: 20px;
  padding-top: 40px;
  background-color: #052e16;
  color: #fff;
  border-radius: 12px;
  position: relative;
}

.plan .pricing {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border-radius: 99em 0 0 99em;
  display: flex;
  align-items: center;
  padding: 0.625em 0.75em;
  font-size: 1.25rem;
  font-weight: 600;
  color: #425475;
}

.plan .pricing small {
  color: #707a91;
  font-size: 0.75em;
  margin-left: 0.25em;
}

.plan .title {
  font-weight: 600;
  font-size: 1.25rem;
  color: #facc15;
}

.plan .title + * {
  margin-top: 0.75rem;
  text-transform: capitalize;
}

.plan .info + * {
  margin-top: 1rem;
}

.plan .features {
  display: flex;
  flex-direction: column;
}

.plan .features li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.plan .features li + * {
  margin-top: 0.75rem;
}

.plan .features .icon {
  background-color: #1fcac5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.plan .features .icon svg {
  width: 14px;
  height: 14px;
}

.plan .features + * {
  margin-top: 1.25rem;
}

.plan .action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.plan .button {
  background-color: #fff;
  border-radius: 6px;
  color: #000;
  font-weight: 500;
  font-size: 1.125rem;
  text-align: center;
  border: 0;
  outline: 0;
  width: 100%;
  padding: 0.625em 0.75em;
  text-decoration: none;
}

.plan .button:hover,
.plan .button:focus {
  background-color: #4caf50;
}

.special-offer-img {
  position: absolute;
  top: -3%;
  left: -3%;
  z-index: 10;
}
