.testimonial-slider {
  width: auto;
  margin: auto;
}

.testimonial-card {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  height: 100%;
}

.testimonial-content {
  margin-bottom: 10px;
}

.testimonial-quote {
  font-size: 1.2rem;
  color: #333; /* Dark text color */
  line-height: 1.5;
}

.testimonial-rating {
  margin: 10px 0;
}

.star {
  color: gold;
  font-size: 1.5rem;
}

.testimonial-user {
  display: flex;
  align-items: left;
  justify-content: left;
  margin-top: 10px;
}

.user-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-info {
  text-align: left;
}

.user-name {
  font-weight: bold;
  margin: 0;
}

.user-title {
  color: #666;
  font-size: 0.9rem;
}
.testimonial-slider {
  width: 100%;
  max-width: 700px;
  min-width: 320px;
  margin: 0 auto;
  padding: 0 1rem;
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.testimonial-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.testimonial-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.testimonial-title {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
}

.testimonial-quote {
  font-size: 1rem;
  color: #777;
  font-style: italic;
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonial-card {
    padding: 15px;
  }

  .testimonial-name {
    font-size: 1.2rem;
  }

  .testimonial-quote {
    font-size: 0.9rem;
  }
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  visibility: hidden !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  visibility: hidden !important;
}

.swiper-scrollbar {
  display: none;
}

.swiper-pagination {
  display: none;
}
